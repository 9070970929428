import React from "react";
import InputTagList from "../../components/input-tag-list.jsx";
import ProviderFilter from "../../components/content-list/filters/provider-filter.jsx";
import ContentTypeFilter from "../../components/content-list/filters/content-type-filter.jsx";
import DurationFilter from "../../components/content-list/filters/duration-filter.jsx";
import SeasonFilter from "../../components/content-list/filters/season-filter.jsx";
import SeriesFilter from "../../components/content-list/filters/series-filter.jsx";
import RatingFilter from "../../components/content-list/filters/rating-filter.jsx";
import GenreFilter, { SubgenreFilter } from "../../components/content-list/filters/genre-filter.jsx";
import getTagsFromFilters from "../../components/content-list/getTagsFromFilters.jsx";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

const contentTypes = [
  {
    value: "features",
    label: "Features",
  },
  {
    value: "episodes",
    label: "Episodes",
  },
  {
    value: "promos",
    label: "Promos",
  },
  {
    value: "series",
    label: "Series",
  },
];
const defaultContentType = contentTypes[0];

function TemplateBlockFilters({ filters, updateFilters, providers, changeBlockTypeToFilter, forChannelId }) {
  const tagsMemo = React.useMemo(() => getTagsFromFilters(filters), [filters]);
  const initialState = {
    search: "",
    providers: [{ value: providers[0].provider_guid, label: providers[0].provider_name }],
    contentType: defaultContentType,
    ratings: [],
    genres: [],
    subgenres: [],
    durationMin: "",
    durationMax: "",
    series: {},
    season: {},
    forChannelId: forChannelId,
  };

  // Filter setters
  function setSearch(input) {
    updateFilters((prev) => ({
      ...prev,
      search: input,
    }));
  }

  const setProvider = React.useCallback(
    (input) => {
      let nextProviders = input;
      if (!nextProviders) {
        nextProviders = [];
      }

      updateFilters((prev) => ({
        ...prev,
        providers: nextProviders,
      }));
    },
    [updateFilters],
  );

  function setContentType(input) {
    // resetSeries();
    updateFilters((prev) => ({
      ...prev,
      contentType: input,
      series: input.value === "episodes" ? prev.series : {},
      season: input.value === "episodes" ? prev.season : {},
    }));
  }

  function setRatings(rating) {
    updateFilters((prev) => {
      const ratings = prev.ratings
        .concat(rating)
        .filter((value, index, self) => index === self.findIndex((t) => t.value === value.value && t.label));
      return {
        ...prev,
        ratings,
      };
    });
  }

  function setGenre(genre) {
    let subgenres = [];
    let existing = filters.genres.length ? filters.genres[0].value : null;
    if (+genre.value === +existing) {
      subgenres = filters.subgenres;
    }

    updateFilters((prev) => ({
      ...prev,
      genres: [genre],
      subgenres,
    }));
  }

  function setSubgenre(subgenre) {
    updateFilters((prev) => ({
      ...prev,
      subgenres: [subgenre],
    }));
  }

  function setDurationMin(event) {
    const durationValue = Math.min(Math.max(0, event.target.value), 999);
    event.target.value = durationValue; // for visibility of changes to value outside range
    updateFilters((prev) => ({
      ...prev,
      durationMin: durationValue ? durationValue : "",
    }));
  }

  function setDurationMax(event) {
    const durationValue = Math.min(Math.max(0, event.target.value), 999);
    event.target.value = durationValue; // for visibility of changes to value outside range
    updateFilters((prev) => ({
      ...prev,
      durationMax: durationValue ? durationValue : "",
    }));
  }

  function setSeries(input) {
    updateFilters((prev) => {
      let season = {};
      if (input.value === prev.series?.value) {
        season = prev.season;
      }

      return {
        ...prev,
        series: input,
        season,
      };
    });
  }

  function setSeason(input) {
    updateFilters((prev) => ({
      ...prev,
      season: input,
    }));
  }

  function onRemoveTag(tag) {
    const keys = Array.isArray(tag) ? tag : [tag];
    const keyOptions = keys.map((tagKey) => {
      const [key, option] = tagKey.split(".");
      return {
        key,
        option,
      };
    });

    updateFilters((prev) => {
      const next = keyOptions
        .map((keyOption) => ({
          key: [keyOption.key],
          value: keyOption.option
            ? prev[keyOption.key].filter((v) => v.value !== keyOption.option)
            : initialState[keyOption.key],
        }))
        .reduce((previousValue, currentValue) => {
          return {
            ...previousValue,
            [currentValue.key]: currentValue.value,
          };
        }, {});

      // clear subgenres
      if (next.genres) {
        next.subgenres = [];
      }

      return {
        ...prev,
        ...next,
      };
    });
  }

  return (
    <div className="content-list__filter content-list-filter" onMouseDown={changeBlockTypeToFilter}>
      <InputTagList tags={tagsMemo} onRemove={onRemoveTag} />

      <div className="content-list-filter__group content-list-filter__group--fixed">
        <div className="input-group input-group--with-icon content-list-filter__search">
          <input
            type="text"
            className="app-text-input"
            placeholder="Search..."
            onChange={(e) => setSearch(e.target.value)}
            value={filters.search}
          />
          <SearchRoundedIcon />
        </div>
      </div>
      <div className="content-list-filter__group">
        <div className="content-list-filter__provider">
          <ProviderFilter providers={providers} selectedProviders={filters.providers} onChange={setProvider} />
        </div>
        <div className="content-list-filter__content-type">
          <ContentTypeFilter contentType={filters.contentType} contentTypes={contentTypes} onChange={setContentType} />
        </div>
        <div className="content-list-filter__ratings">
          <RatingFilter selectedRatings={filters.ratings} onChange={setRatings} />
        </div>
        <div className="content-list-filter__ratings">
          <GenreFilter selectedGenres={filters.genres} onChange={setGenre} />
        </div>
        <div className="content-list-filter__ratings">
          <SubgenreFilter
            selectedGenres={filters.genres}
            selectedSubgenres={filters.subgenres}
            onChange={setSubgenre}
          />
        </div>
        <div className="content-list-filter__duration">
          <DurationFilter
            onChangeMax={setDurationMax}
            onChangeMin={setDurationMin}
            min={filters.durationMin}
            max={filters.durationMax}
          />
        </div>
        {filters.contentType.value === "episodes" ? (
          <div className="content-list-filter__series">
            <SeriesFilter
              channelId={forChannelId}
              providerGuids={filters.providers.map((p) => p.value)}
              selectedSeries={filters.series}
              onChange={setSeries}
            />
          </div>
        ) : null}
        {filters.contentType.value === "episodes" ? (
          <div className="content-list-filter__seasons">
            <SeasonFilter seriesId={filters.series.value} season={filters.season} onChange={setSeason} />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default TemplateBlockFilters;
