import React from "react";
import { addMinutes, addSeconds, differenceInSeconds, subMinutes } from "date-fns";
import { toast } from "react-toastify";
import { Droppable } from "react-beautiful-dnd";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { v4 as uuid } from "uuid";
import dateIsBetween from "../../common/date-is-between";
import { dateToHourMinutesSeconds } from "../../common/duration-formatting";
import { getHeightStyleFromSeconds } from "../../components/vertical-scheduler/helpers/style-helpers";
import {
  getHeightFromSeconds,
  getMarkerPopOutWidth,
  getTimeRemainingInSegment,
  getLastProgramForSegment,
  getProgramVerticalOffset,
} from "../../components/vertical-scheduler/helpers/scheduler-helpers";
import { getSmallOffset, getPrependedMargin } from "./helpers/template-helpers";

import useSegments from "../../components/vertical-scheduler/hooks/useSegments";

import CalendarMarkers from "../../components/vertical-scheduler/calendarMarkers";
import { getTimezoneDifference } from "../../common/clock-change-safe-time";
import DraggableTemplateItem from "./draggable-template-item";
import { trimProgramDurationToSeconds } from "../epg-editor/utils/trim-program-duration";
import CopySegmentDialog from "./dialogs/copy-segment-dialog";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import TemplateAddBlockButton from "./template-add-block-button";

const SMALL_PROGRAM_MAX_HEIGHT = 50;
const MODELS_WITH_CUEPOINTS = ["feature", "episode"];

function TemplateScheduler({
  templateItems,
  refDate,
  templateDayBreaks,
  droppableId,
  addDayBreak,
  deleteDayBreak,
  removeItem,
  copySegment,
  updateItems,
  setActiveContent,
  setActiveBlock,
  insertionThreshold,
  dayNumber,
  addBlock = () => {},
  isDropDisabled = () => {},
}) {
  // const [targetSegmentIndex, setTargetSegmentIndex] = React.useState(0);
  const [markerPopoutWidth, setMarkerPopoutWidth] = React.useState();
  const [copySegmentDialog, setCopySegmentDialog] = React.useState({
    isOpen: false,
  });

  const calendarDiv = React.useRef();
  const timelineContainerRef = React.useRef();
  const segments = useSegments(refDate, templateDayBreaks, templateItems);
  const pixelsPerSection = 4;
  const secondsPerSection = 60; // change this when changing active availableMarkerMinuteSpread value

  const updateItemDuration = React.useCallback(
    (itemId, newDuration) => {
      const sourceItem = templateItems.find((item) => item.drag_id === itemId);

      let trimmedItem = {};
      if (MODELS_WITH_CUEPOINTS.includes(sourceItem.type)) {
        trimmedItem = {
          ...trimProgramDurationToSeconds(sourceItem, newDuration, null, true),
          duration: newDuration,
          total_duration_seconds: newDuration,
        };
      } else {
        trimmedItem = {
          ...sourceItem,
          duration: newDuration,
          total_duration_seconds: newDuration,
          till: addSeconds(new Date(sourceItem.since), newDuration),
          __gstvMeta: {
            ...sourceItem.__gstvMeta,
            duration_seconds: newDuration,
            total_duration_seconds: newDuration,
          },
        };
      }

      updateItems(itemId, trimmedItem);
    },
    [templateItems, updateItems],
  );

  const openCopyDialog = React.useCallback((targetSegmentIndex) => {
    setCopySegmentDialog({
      isOpen: true,
      referenceIndex: targetSegmentIndex,
    });
  }, []);

  const closeCopyDialog = React.useCallback(() => {
    setCopySegmentDialog({
      isOpen: false,
    });
  }, []);

  React.useLayoutEffect(() => {
    const observer = new ResizeObserver(() => {
      setMarkerPopoutWidth(getMarkerPopOutWidth(calendarDiv));
    });

    if (calendarDiv.current) {
      observer.observe(calendarDiv.current);
    } else {
      // hack to make sure we avoid timing issues
      setTimeout(() => {
        if (calendarDiv.current) {
          observer.observe(calendarDiv.current);
        }
      }, 300);
    }

    return () => observer.disconnect();
  }, []);

  // early exit if we don't have all the information
  if (templateDayBreaks.length === 0) {
    // we still use some default plan_breaks from channel
    return null;
  }

  function addBreakAtPosition(date) {
    const [segment] = segments.filter((segment) => {
      return dateIsBetween(date, segment.start, segment.end, "(]");
    });

    if (!segment) {
      toast.error("Unable to add a break there.");
      return;
    }

    // If overlapping with an item, set to either start or end of the program
    if (segment.items.length > 0) {
      let fixedDate = null;
      segment.items.forEach((item) => {
        if (item.till > date && item.since < date) {
          // see which is closer, since or till
          const sinceDiff = Math.abs(differenceInSeconds(date, item.since));
          const tillDiff = Math.abs(differenceInSeconds(date, item.till));
          // ensure we are no conflicting with segment
          fixedDate = sinceDiff > tillDiff ? new Date(item.till) : new Date(item.since);
        }
      });

      if (+segment.start === +fixedDate || +segment.end === +fixedDate) {
        toast.error("Unable to add a break there.");
        return;
      }

      date = fixedDate ?? date;
    }

    addDayBreak(date);
  }

  function addBreak(minutesFromStartOfDay) {
    const start = refDate;
    let date = addMinutes(start, minutesFromStartOfDay);
    // we need to consider that the timezone may change from the start of the day to the point we want to add it
    // for example BST -> GMT
    const timezoneSafetyCheck = getTimezoneDifference(start, date);
    if (timezoneSafetyCheck) {
      // we are on a special day
      date = subMinutes(date, timezoneSafetyCheck);
    }
    addBreakAtPosition(date);
  }

  function executeSegmentCopy(sourceSegmentIndex, targetSegmentIndex) {
    copySegment(
      JSON.parse(
        JSON.stringify(
          segments[sourceSegmentIndex].items.map((item) => ({
            ...item,
            drag_id: uuid(), // unique id
            __gstvMeta: { ...item.__gstvMeta, program_id: null },
          })),
        ),
      ),
      segments[targetSegmentIndex].start,
      segments[targetSegmentIndex].items ? segments[targetSegmentIndex].items.length : 0,
    );
    toast.success("Copy Successful");
    closeCopyDialog();
  }

  function segmentCanInsert(segment) {
    return (
      differenceInSeconds(
        segment.end,
        segment.items.length ? segment.items[segment.items.length - 1].till : segment.start,
      ) >= insertionThreshold
    );
  }

  return (
    <div className="vertical-scheduler" ref={timelineContainerRef}>
      <CalendarMarkers
        planDate={refDate}
        addBreak={addBreak}
        containerRef={timelineContainerRef}
        secondsPerSection={secondsPerSection}
        pixelsPerSection={pixelsPerSection}
        popoutWidth={markerPopoutWidth}
        changeZoomLevel={() => {}}
      />
      <div className="vertical-scheduler-calendar" ref={calendarDiv}>
        {segments.map((seg, sIndex) => (
          <div
            className="vertical-scheduler-calendar__segment"
            key={sIndex}
            style={{
              height: getHeightStyleFromSeconds(
                differenceInSeconds(seg.end, seg.start),
                secondsPerSection,
                pixelsPerSection,
              ),
              ...(sIndex > 0 && getLastProgramForSegment(sIndex - 1, segments)?.till > segments[sIndex - 1].end
                ? {
                    paddingTop: `${getProgramVerticalOffset(segments[sIndex - 1], secondsPerSection, pixelsPerSection)}`,
                  }
                : {}),
            }}
          >
            <Droppable
              droppableId={`${droppableId}-${seg.label}`}
              direction="vertical"
              key={sIndex}
              ignoreContainerClipping={true}
              isDropDisabled={isDropDisabled(droppableId)}
            >
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={`vertical-scheduler-calendar__plan`}
                >
                  <div className="vertical-scheduler-calendar__bottom">
                    <div className="vertical-scheduler-calendar__pill vertical-scheduler-calendar__pill--secondary">
                      <div className="vertical-scheduler-calendar__pill__text">
                        {getTimeRemainingInSegment(seg, templateItems)}
                      </div>
                    </div>
                    <div className="vertical-scheduler-calendar__pill">
                      <span className="vertical-scheduler-calendar__pill__text">
                        {dateToHourMinutesSeconds(seg.end)}
                      </span>
                      {segments[sIndex + 1] ? (
                        <button
                          className="btn btn--icon vertical-scheduler__btn-copy"
                          onClick={() => openCopyDialog(sIndex)}
                        >
                          <ContentCopyRoundedIcon />
                        </button>
                      ) : null}
                      {segments[sIndex + 1] && segments[sIndex].type === "plan" ? (
                        <button
                          className="btn btn--icon vertical-scheduler__btn-delete"
                          onClick={() => deleteDayBreak(segments[sIndex + 1].start)}
                        >
                          <CancelRoundedIcon />
                        </button>
                      ) : null}
                    </div>
                  </div>
                  {seg.items.map((item, index) => (
                    <DraggableTemplateItem
                      item={item}
                      droppableId={droppableId}
                      segment={seg}
                      updateDuration={updateItemDuration}
                      onItemClick={() => {}}
                      onItemDoubleClick={
                        item.type === "template_block" ? (item) => setActiveBlock(segments, item) : setActiveContent
                      }
                      removeItem={removeItem}
                      secondsPerSection={secondsPerSection}
                      pixelsPerSection={pixelsPerSection}
                      index={index}
                      onItemDrag={() => {}}
                      getHeightFromSeconds={getHeightFromSeconds.bind(null, secondsPerSection, pixelsPerSection)}
                      smallDuration={SMALL_PROGRAM_MAX_HEIGHT}
                      smallOffset={getSmallOffset(
                        index,
                        seg.items,
                        SMALL_PROGRAM_MAX_HEIGHT,
                        secondsPerSection,
                        pixelsPerSection,
                      )}
                      prependMargin={getPrependedMargin(
                        index,
                        seg.items,
                        SMALL_PROGRAM_MAX_HEIGHT,
                        secondsPerSection,
                        pixelsPerSection,
                      )}
                      isLastItem={index === seg.items.length - 1}
                      addBlockThreshold={insertionThreshold}
                      segmentCanInsert={segmentCanInsert(seg)}
                      addBlock={() => addBlock(dayNumber, sIndex, segments)}
                      key={index}
                    />
                  ))}
                  {!seg.items || !seg.items.length ? (
                    <TemplateAddBlockButton
                      addBlock={() => addBlock(dayNumber, sIndex, segments)}
                      addBlockThreshold={insertionThreshold}
                      secondsPerSection={secondsPerSection}
                      pixelsPerSection={pixelsPerSection}
                    />
                  ) : null}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            <div className="vertical-scheduler__extra"></div>
          </div>
        ))}
      </div>
      <CopySegmentDialog
        isOpen={copySegmentDialog.isOpen}
        referenceIndex={copySegmentDialog.referenceIndex}
        onClose={closeCopyDialog}
        onValidationPass={executeSegmentCopy}
        segments={segments}
        key={`copy_dialog-${uuid()}`}
      />
    </div>
  );
}

export default TemplateScheduler;
