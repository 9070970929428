import React from "react";
import ReactModal from "react-modal";
import Select from "../../../common/select.jsx";
import { dateToHourMinutesSeconds } from "../../../common/duration-formatting.js";
import TemplateBlockFilters from "../template-block-filters.jsx";

const contentTypes = [
  {
    value: "features",
    label: "Features",
  },
  {
    value: "episodes",
    label: "Episodes",
  },
  {
    value: "promos",
    label: "Promos",
  },
  {
    value: "series",
    label: "Series",
  },
];
const defaultContentType = contentTypes[0];

export default function AddBlockDialog({
  isOpen,
  onClose,
  dayNumber,
  segIndex,
  segments = [],
  providers = [],
  onSubmit,
  channelId,
}) {
  const initialState = {
    search: "",
    providers: providers.length ? [{ value: providers[0].provider_guid, label: providers[0].provider_name }] : [],
    contentType: defaultContentType,
    ratings: [],
    genres: [],
    subgenres: [],
    durationMin: "",
    durationMax: "",
    series: {},
    season: {},
    forChannelId: channelId,
  };
  const [blockType, setBlockType] = React.useState("copy");
  const [blockName, setBlockName] = React.useState("");

  const [segmentOptions, setSegmentOptions] = React.useState([]);
  const [sourceSegmentIndex, setSourceSegmentIndex] = React.useState(-1);

  const [filters, setFilters] = React.useState(initialState);

  React.useEffect(() => {
    segmentsToDropdownOptions(segments);
  }, [segments]);

  function segmentsToDropdownOptions(segments) {
    if (segments.length === 0) {
      return;
    }

    const options = segments.map((segment, index) => {
      return {
        value: index,
        label: `${dateToHourMinutesSeconds(segment.start)} - ${dateToHourMinutesSeconds(segment.end)}`,
      };
    });

    setSegmentOptions(options);
  }

  function setTypeToCopy() {
    if (blockType !== "copy") {
      setFilters(initialState);
      setBlockType("copy");
    }
  }

  function setTypeToFilter() {
    if (blockType !== "filter") {
      setSourceSegmentIndex(-1);
      setBlockType("filter");
    }
  }

  function submitFormData() {
    let blockFilters = null;
    let sourceSegment = null;
    let sourceIndex = null;
    let copyFrom = null;
    let startTime = segments[segIndex].items?.length
      ? segments[segIndex].items[segments[segIndex].items.length - 1].till // blocks always added at end of a segment
      : segments[segIndex].start;

    if (blockType === "copy") {
      sourceSegment = segments[sourceSegmentIndex];
      sourceIndex = segmentOptions[sourceSegmentIndex].value;
      copyFrom = sourceSegment.start;
    }

    if (blockType === "filter") {
      blockFilters = { ...filters };
    }

    return {
      // @note this is a block definition
      blockName: blockName,
      type: blockType,
      dayNumber,
      sourceIndex,
      segment: segments[segIndex],
      start: startTime,
      end: segments[segIndex].end, // default end, adjusted in conversion
      filters: blockFilters,
      sourceSegment,
      copyFrom, // date
    };
  }

  function onConfirm() {
    // @TODO add validation on copy to make sure we have space(time) to copy the selected source into the available space

    onSubmit(submitFormData());
    onClose();
  }

  function onSegmentChange(input) {
    setTypeToCopy();
    setSourceSegmentIndex(input.value);
  }

  return (
    <ReactModal isOpen={isOpen} ariaHideApp={false}>
      <div className="v-react-modal__inner modal-copy">
        <div className="v-react-modal__heading">Add Template Block</div>
        <div className="v-react-modal__body modal-copy__body">
          <div className="modal-copy__dropdown-group">
            <div className="modal-copy__body__text">Block name:</div>
            <div className="modal-copy__dropdown">
              <input
                type="text"
                name="blockName"
                id="blockName"
                value={blockName}
                placeholder="If not filled, a default name will be provided"
                onChange={(e) => setBlockName(e.target.value)}
              />
            </div>
          </div>
          <br />
          <hr />
          <div className="modal-copy__dropdown-group">
            <div className="modal-copy__body__text">Copy from:</div>
            <div className="modal-copy__dropdown">
              <Select
                value={
                  segmentOptions.length > 0 && sourceSegmentIndex > -1
                    ? segmentOptions[sourceSegmentIndex]
                    : { value: "Select", label: "Select a segment" }
                }
                name={"copySource"}
                options={segmentOptions.filter((seg, index) => index !== segIndex)}
                onChange={onSegmentChange}
                classNamePrefix="react-select"
                isSearchable={false}
              />
            </div>
          </div>
          OR
          <br />
          <TemplateBlockFilters
            filters={filters}
            updateFilters={setFilters}
            providers={providers}
            changeBlockTypeToFilter={setTypeToFilter}
            forChannelId={channelId}
          />
        </div>
        <div className="v-react-modal__footer">
          <button className="btn btn--inverse-primary" onClick={onClose} key="btn-back">
            Cancel
          </button>
          <button className="btn btn--primary" onClick={onConfirm} key="btn-yes">
            Create {blockType === "copy" ? "Segment Copy Block" : "Filter Block"}
          </button>
        </div>
      </div>
    </ReactModal>
  );
}
